<script>
import validate from "@/helpers/validate";
import { register } from "@/helpers/auth";
import appConfig from "../../../app.config";

export default {
  page: {
    title: "Регистрация",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
  },
  data() {
    return {
      user: {
        login: '',
        email: '',
        password: '',
        confirm: '',
      },
      errors: {},
      loading: false,
    };
  },
  methods: {
    tryToRegisterIn() {
      this.errors = {};
      this.errors.email = validate.email(this.user.email);
      this.errors.login = validate.login(this.user.login);
      this.errors.password = validate.password(this.user.password);
      this.errors.confirm = validate.confirm(this.user.confirm, this.user.password);

      if (this.errors.email ||
          this.errors.login ||
          this.errors.password ||
          this.errors.confirm) return;

      this.loading = true;
      register(this.user, {
        end: () => {
          this.loading = false;
        }
      });
    },
  },
}
</script>

<template>
    <div class="auth-page-wrapper pt-5">
            <!-- auth page bg -->
            <div class="auth-one-bg-position auth-one-bg"  id="auth-particles">
                <div class="bg-overlay"></div>
                
                <div class="shape">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                        <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                    </svg>
                </div>
            </div>

            <!-- auth page content -->
            <div class="auth-page-content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-center mt-sm-5 mb-4 text-white-50">
                                <div>
                                    <router-link to="/" class="d-inline-block auth-logo">
                                        <img src="@/assets/images/logo-light.png" alt="" height="40">
                                    </router-link>
                                </div>
                                <p class="mt-3 fs-15 fw-medium">
                                  Добро пожаловать домой!
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->

                    <div class="row justify-content-center" >
                        <div class="col-md-8 col-lg-6 col-xl-5">
                            <div class="card mt-4" data-aos="fade-up">
                              <div v-if="loading" class="card-preloader d-flex flex-column align-items-center justify-content-center">
                                <div class="spinner-border text-primary" role="status">
                                  <span class="sr-only"></span>
                                </div>
                                <p class="mt-2">Регистрация...</p>
                              </div>
                            
                                <div class="card-body p-4"> 
                                    <div class="text-center mt-2">
                                      <h5 class="text-primary">Добро пожаловать!</h5>
                                      <p class="text-muted">Зарегистрируйтесь, чтобы начать</p>
                                    </div>
                                    <div class="p-2 mt-4">
                                        <form @submit.prevent="tryToRegisterIn">
                                            <div class="mb-3">
                                                <label for="login" class="form-label">
                                                  Имя пользователя <span class="text-danger">*</span>
                                                </label>
                                                <input type="text"
                                                       class="form-control"
                                                       v-model="user.login"
                                                      :class="{
                                                        'is-invalid': errors.login,
                                                      }"
                                                       id="login"
                                                       placeholder="Укажите имя пользователя"
                                                >
                                                <div
                                                    v-for="(item, index) in errors.login"
                                                    :key="index"
                                                    class="invalid-feedback"
                                                >
                                                  <span v-if="item"> {{ item }} </span>
                                                </div>
                                            </div>

                                            <div class="mb-3">
                                              <label for="email" class="form-label">
                                                Email адрес <span class="text-danger">*</span>
                                              </label>
                                              <input type="text"
                                                     class="form-control"
                                                     v-model="user.email"
                                                     id="email"
                                                     :class="{
                                                          'is-invalid': errors.email,
                                                        }"
                                                     placeholder="Введите email адрес"
                                              >

                                              <div
                                                  v-for="(item, index) in errors.email"
                                                  :key="index"
                                                  class="invalid-feedback"
                                              >
                                                <span v-if="item"> {{ item }} </span>
                                              </div>
                                            </div>

                                            <div class="mb-3">
                                                <label for="password" class="form-label">
                                                  Пароль <span class="text-danger">*</span>
                                                </label>
                                                <input type="password"
                                                       class="form-control"
                                                       v-model="user.password"
                                                       :class="{
                                                          'is-invalid': errors.password,
                                                        }"
                                                       id="password"
                                                       placeholder="Введите пароль"
                                                >

                                                <div
                                                    v-for="(item, index) in errors.password"
                                                    :key="index"
                                                    class="invalid-feedback"
                                                >
                                                  <span v-if="item"> {{ item }} </span>
                                                </div>
                                            </div>

                                          <div class="mb-2">
                                            <label for="confirm" class="form-label">
                                              Подтвердите пароль <span class="text-danger">*</span>
                                            </label>
                                            <input type="password"
                                                   class="form-control"
                                                   v-model="user.confirm"
                                                   :class="{
                                                          'is-invalid': errors.confirm,
                                                        }"
                                                   id="confirm"
                                                   placeholder="Введите пароль ещё раз"
                                            >

                                            <div
                                                v-for="(item, index) in errors.confirm"
                                                :key="index"
                                                class="invalid-feedback"
                                            >
                                              <span v-if="item"> {{ item }} </span>
                                            </div>
                                          </div>

                                            <div class="mb-4">
                                                <p class="mb-0 fs-12 text-muted fst-italic">
                                                  Регистрируясь здесь, Вы соглашаетесь с
                                                  <a href="#" class="text-primary text-decoration-underline fst-normal fw-medium">
                                                    нашей политикой
                                                  </a>
                                                </p>
                                            </div>
                                            
                                            <div class="mt-4">
                                                <button class="btn btn-success w-100" type="submit">Зарегистрироваться</button>
                                            </div>

<!--                                            <div class="mt-4 text-center">-->
<!--                                                <div class="signin-other-title">-->
<!--                                                    <h5 class="fs-13 mb-4 title text-muted">Create account with</h5>-->
<!--                                                </div>-->

<!--                                                <div>-->
<!--                                                    <button type="button" class="btn btn-primary btn-icon waves-effect waves-light"><i class="ri-facebook-fill fs-16"></i></button>-->
<!--                                                    <button type="button" class="btn btn-danger btn-icon waves-effect waves-light ms-1"><i class="ri-google-fill fs-16"></i></button>-->
<!--                                                    <button type="button" class="btn btn-dark btn-icon waves-effect waves-light ms-1"><i class="ri-github-fill fs-16"></i></button>-->
<!--                                                    <button type="button" class="btn btn-info btn-icon waves-effect waves-light ms-1"><i class="ri-twitter-fill fs-16"></i></button>-->
<!--                                                </div>-->
<!--                                            </div>-->
                                        </form>

                                    </div>
                                </div>
                                <!-- end card body -->
                            </div>
                            <!-- end card -->

                            <div class="mt-4 text-center" data-aos="fade-in">
                                <p class="mb-0">Уже имеете аккаунт? <router-link to="/login" class="fw-semibold text-primary text-decoration-underline"> Авторизуйтесь </router-link> </p>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->
                </div>
                <!-- end container -->
            </div>
            <!-- end auth page content -->

            <!-- footer -->
            <footer class="footer">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                          <div class="text-center">
                            <p class="mb-0 text-muted">
                              &copy; {{new Date().getFullYear()}} FinalDream. Сделано с любовью
                              <i class="mdi mdi-heart text-danger"></i>
                            </p>
                          </div>
                        </div>
                    </div>
                </div>
            </footer>
            <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
</template>